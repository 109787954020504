import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'utils';
import { Dropdown } from 'RaisisComponents';
import { Box, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { TableSeparator } from 'RaisisComponents';

const ClientSubscriptionDetails = ({ client }) => {
    const { SubscriptionDetailes: subscriptions } = client;
    const [activeSub, setActiveSub] = useState(subscriptions.find((sub) => Boolean(sub.isActive)).id);

    const subscription = subscriptions.find((sub) => sub.id === activeSub);
    const {
        subscriptionType,
        isActive,
        startDate,
        endDate,
        numberOfContacts,
        numberOfContracts,
        numberOfInvoices,
        numberOfOffers,
        numberOfProjects,
        watcherAccounts,
        questionsToAsk,
        extraContacts,
        extraContracts,
        extraInvoices,
        extraOffers,
        extraProjects,
        extraWatcher,
        extraQuestions,
        contacts,
        contracts,
        invoices,
        offers,
        projects,
        watchers,
        questions,
    } = subscription;

    return (
        <div className="flex flex-col gap-8">
            <div className="flex w-max gap-32 rounded-xl bg-layout-background px-6 py-4 sm:w-full sm:flex-col sm:gap-16">
                <div className="flex flex-shrink-0 flex-col gap-4">
                    <h3 className="mb-2 text-2xl font-bold">Detalii subscripție selectată</h3>
                    <p className="text-xl">
                        Tip subscripție: <span className="font-bold">{subscriptionType}</span>
                    </p>
                    <p className="text-xl">
                        Status: <span className="font-bold">{isActive ? 'Activă' : 'Inactivă'}</span>
                    </p>
                    <p className="text-xl">
                        Data de început: <span className="font-bold">{formatDate(startDate)}</span>
                    </p>
                    <p className="text-xl">
                        Data de sfârșit: <span className="font-bold">{formatDate(endDate)}</span>
                    </p>
                </div>
                <div className="flex flex-col gap-6">
                    <h3 className="text-2xl font-bold">Selectează subscripția</h3>
                    <div className="w-max">
                        <Dropdown
                            selectedOption={subscriptions.findIndex((sub) => sub.id === activeSub)}
                            setSelectedOption={(index) => setActiveSub(subscriptions[index].id)}
                            options={subscriptions.map(
                                (sub) =>
                                    `${formatDate(sub.startDate)} - ${formatDate(sub.endDate)} - ${sub.isActive ? 'ACTIVĂ' : 'INACTIVĂ'}`,
                            )}
                        />
                    </div>
                </div>
            </div>
            <TableContainer component={Box}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Criteriu</TableCell>
                            <TableCell>Limită subscripție</TableCell>
                            <TableCell>Folosite</TableCell>
                            <TableCell>Peste limita subscripției</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableSeparator />
                        <TableRow>
                            <TableCell>Clienți</TableCell>
                            <TableCell>{numberOfContacts}</TableCell>
                            <TableCell>{contacts}</TableCell>
                            <TableCell>{extraContacts}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Contracte</TableCell>
                            <TableCell>{numberOfContracts}</TableCell>
                            <TableCell>{contracts}</TableCell>
                            <TableCell>{extraContracts}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Facturi</TableCell>
                            <TableCell>{numberOfInvoices}</TableCell>
                            <TableCell>{invoices}</TableCell>
                            <TableCell>{extraInvoices}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Oferte</TableCell>
                            <TableCell>{numberOfOffers}</TableCell>
                            <TableCell>{offers}</TableCell>
                            <TableCell>{extraOffers}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Proiecte</TableCell>
                            <TableCell>{numberOfProjects}</TableCell>
                            <TableCell>{projects}</TableCell>
                            <TableCell>{extraProjects}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Investitori</TableCell>
                            <TableCell>{watcherAccounts}</TableCell>
                            <TableCell>{watchers}</TableCell>
                            <TableCell>{extraWatcher}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Întrebari</TableCell>
                            <TableCell>{questionsToAsk}</TableCell>
                            <TableCell>{questions}</TableCell>
                            <TableCell>{extraQuestions}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

ClientSubscriptionDetails.propTypes = {
    client: PropTypes.object,
};
ClientSubscriptionDetails.defaultProps = {
    client: {},
};

export default ClientSubscriptionDetails;
