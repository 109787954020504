import React, { useContext, useEffect, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DatePicker, Dropdown, LabelWrapper, Toggle } from 'RaisisComponents';
import PhotoIcon from '@material-ui/icons/Photo';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import API from 'utils/axios';
import { IBAN_REGEX, PHONE_NO_REGEX, SUBDOMAIN_REGEX, errorMessages, errorHandling, uploadSingleFile } from 'utils';
import { useSnackbar } from 'notistack';
import FileUploadContainer from 'components/file-upload-container';
import CircularProgress from '@material-ui/core/CircularProgress';
import SubscriptionTypeContext from 'contexts/SubscriptionTypeContext';
import { useHistory } from 'react-router-dom';
import { clientRoutes } from 'routes';

const dummyTypes = ['SALES', 'SERVICES'];
const dummyTypesTranslations = {
    SALES: 'VÂNZĂRI',
    SERVICES: 'SERVICII',
};

const ClientDetailsForm = (props) => {
    const history = useHistory();
    const subscriptionTypes = useContext(SubscriptionTypeContext);

    const { editable, client, submitButton, onSuccess, canInteractWithAllFields, canDisplayTitle } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const [logoBlob, setLogoBlob] = useState(null);
    const [iconBlob, setIconBlob] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [subscriptionType, setSubscriptionType] = useState(null);
    const [isDummy, setIsDummy] = useState(false);
    const [dummyType, setDummyType] = useState(null);

    useEffect(() => {
        if (!client) return;

        const subscriptionTypeIndex = subscriptionTypes.findIndex(
            (st) => st.id === client?.subscription?.subscriptionTypeId,
        );

        if (subscriptionTypeIndex >= 0) {
            setSubscriptionType(subscriptionTypeIndex);
            setStartDate(client.subscription?.startDate);
        }
    }, [client, subscriptionTypes]);

    // Upload Functions
    const handleImageUpload = async (e) => {
        await uploadSingleFile(e, ({ message, blob }) => {
            if (message) {
                enqueueSnackbar(message, { variant: 'error' });
                return;
            }
            setLogoBlob(blob);
        });
    };

    const handleIconUpload = async (e) => {
        await uploadSingleFile(e, ({ message, blob }) => {
            if (message) {
                enqueueSnackbar(message, { variant: 'error' });
                return;
            }
            setIconBlob(blob);
        });
    };

    // Client Details
    const validationSchema = yup.object({
        name: yup
            .string()
            .typeError('Numele clientului este obligatoriu!')
            .min(2, 'Numele clientului trebuie să conțină cel puțin 2 caractere!')
            .trim()
            .required('Numele clientului este obligatoriu!'),
        subdomain: yup
            .string()
            .trim()
            .typeError('Subdomeniul este obligatoriu!')
            .matches(SUBDOMAIN_REGEX, 'Subdomeniul nu este valabil!')
            .min(5, 'Subdomeniul trebuie să conțină cel puțin 5 litere!')
            .required('Subdomeniul este obligatoriu!'),
        companyName: yup
            .string()
            .typeError('Numele firmei este obligatoriu!')
            .min(1, 'NUmele firmei trebuie să conținp cel puțin un caractere!')
            .trim()
            .required('Numele firmei este obligatoriu!'),
        companyRegisterNo: yup.string().typeError('Numărul de înregistrare al companiei este obligatoriu!').trim(),
        // .required('Numărul de înregistrare al companiei este obligatoriu!'),
        cui: yup.string().typeError('CUI/CIF-ul companiei este obligatoriu!').trim(),
        // .required('CUI/CIF-ul companiei este obligatoriu!'),
        addressString: yup.string().typeError('Adresa companiei este obligatorie!').trim(),
        // .required('Adresa companiei este obligatorie!'),
        email: yup
            .string()
            .trim()
            .typeError('Adresa de email este obligatorie!')
            .email('Adresa de email nu este validă!')
            .required('Adresa de email este obligatorie!'),
        phoneNo: yup
            .string()
            .trim()
            .typeError('Numărul de telefon este obligatoriu!')
            .matches(PHONE_NO_REGEX, 'Numărul de telefon nu este valabil!')
            .min(10, 'Numărul de telefon trebuie să conțină cel puțin 10 cifre!'),
        // .required('Numărul de telefon este obligatoriu!'),
        administratorName: yup
            .string()
            .typeError('Administratorul companiei este obligatoriu!')
            .trim()
            .min(5, 'Numele administratorului companiei trebuie să conțină cel puțin 5 caractere!'),
        // .required('Administratorul companiei este obligatoriu!'),
        bank: yup.string().typeError().trim().min(3, 'Numele băncii trebuie să conțină cel puțin 3 caractere!'),
        // .required('Numele băncii este obligatoriu!'),
        iban: yup
            .string()
            .typeError('IBAN-ul contului bancar este obligatoriu!')
            .trim()
            .matches(IBAN_REGEX, errorMessages.iban),
        //.required('IBAN-ul contului bancar este obligatoriu!'),
        occupationDescription: yup.string().typeError('Trebuie să adăugați activitatea firmei!').trim(),
        // .required('Trebuie să adăugați activitatea firmei!'),
    });

    function filterEmptyValues(obj) {
        const filteredEntries = Object.entries(obj).filter(([_, value]) => {
            return value !== '' && value !== null && value !== undefined;
        });

        const filteredObj = Object.fromEntries(filteredEntries);

        return filteredObj;
    }

    /**
     * Create Client Function
     */

    const createClientFunc = async (values) => {
        const reqBody = new FormData();
        const newValues = filterEmptyValues(values);

        if (startDate === null) {
            enqueueSnackbar('Data de început a contractului este obligatorie!', { variant: 'error' });
            return;
        }

        if (subscriptionType === null) {
            enqueueSnackbar('Tipul de abonament este obligatoriu!', { variant: 'error' });
            return;
        }

        if (isDummy && dummyType === null) {
            enqueueSnackbar('Tipul de date este obligatoriu!', { variant: 'error' });
            return;
        }

        const body = {
            ...newValues,
            isDummy,
            type: isDummy ? dummyTypes[dummyType] : undefined,
            subscription: {
                startDate,
                subscriptionTypeId: subscriptionTypes[subscriptionType].id,
            },
        };

        delete body.bank;
        delete body.iban;

        reqBody.append('data', JSON.stringify(body));

        try {
            setLoading(true);
            const res = await API.post('customer_creation', reqBody, { 'Content-Type': 'multipart/form-data' });

            if (isDummy) {
                enqueueSnackbar('Clientul a fost adăugat cu succes!', { variant: 'success' });
                history.push(clientRoutes.read);
                return;
            }

            const newClient = {
                ...values,
                id: res.data.data.id,
                subscriptionId: res.data.data.subscriptionId,
                activeThemeId: res.data.data.activeThemeId,
                canChangeTheme: res.data.data.canChangeTheme,
                ThemeConfigurator: res.data.data.ThemeConfigurator,
            };

            onSuccess(newClient);
            enqueueSnackbar('Clientul a fost adăugat cu succes!', { variant: 'success' });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Update Client Function
     */
    const updateClientFunc = async (values) => {
        const reqBody = new FormData();
        const newValues = filterEmptyValues(values);

        const body = {
            ...newValues,
            id: client.id,
        };

        if (values.bank && values.iban)
            body.customerBankInfo[0] = {
                bank: values.bank,
                iban: values.iban,
            };

        delete body.bank;
        delete body.iban;

        reqBody.append('data', JSON.stringify(body));
        if (logoBlob) reqBody.append('logo', logoBlob);
        if (iconBlob) reqBody.append('icon', iconBlob);

        try {
            setLoading(true);
            const res = await API.put('/customer_update', reqBody, { 'Content-Type': 'multipart/form-data' });

            const newClient = {
                ...client,
                ...values,
                ThemeConfigurator: res.data.data.ThemeConfigurator,
            };

            onSuccess(newClient);
            enqueueSnackbar(`Clientul ${values.name} a fost modificat cu succes!`, { variant: 'success' });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            name: client ? client.name : '',
            subdomain: client ? client.subdomain : '',
            companyName: client ? client.companyName : '',
            companyRegisterNo: client ? client.companyRegisterNo : '',
            cui: client ? client.cui : '',
            addressString: client ? client.addressString : '',
            email: client ? client.email : '',
            phoneNo: client ? client.phoneNo : '',
            administratorName: client ? client.administratorName : '',
            bank: client ? client?.customerBankInfo?.[0]?.bank : '',
            iban: client ? client?.customerBankInfo?.[0]?.iban : '',
            occupationDescription: client ? client.occupationDescription : '',
        },
        validationSchema,
        onSubmit: async (values) => {
            if (!client) createClientFunc(values);
            else updateClientFunc(values);
        },
    });

    return (
        <>
            {loading ? (
                <div className="flex h-screen w-full items-center justify-center bg-black-main">
                    <CircularProgress />
                </div>
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="grid w-full max-w-6xl grid-cols-2 gap-8 2xl:gap-24 xl:gap-16"
                >
                    {/* Detalii client */}
                    <div>
                        {/* <h3 className="mb-12">Detalii client &amp; facturare</h3> */}
                        {canDisplayTitle && <h3 className="mb-12">Detalii client</h3>}

                        <div className="mb-5">
                            <TextField
                                name="name"
                                label="Nume client"
                                placeholder="ex: M2M Solutions"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                disabled={!editable}
                            />
                        </div>

                        <div className="mb-5">
                            <TextField
                                name="subdomain"
                                label="Subdomeniu"
                                placeholder="subdomeniu.m2msolution.dev"
                                value={formik.values.subdomain}
                                onChange={formik.handleChange}
                                error={formik.touched.subdomain && Boolean(formik.errors.subdomain)}
                                helperText={formik.touched.subdomain && formik.errors.subdomain}
                                disabled={client ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start" className="text-white">
                                            .{process.env.REACT_APP_DOMAIN_URL}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <div className="mb-5">
                            <TextField
                                name="companyName"
                                label="Nume firmă"
                                placeholder="ex: M2M Property Solutions SRL"
                                value={formik.values.companyName}
                                onChange={formik.handleChange}
                                error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                helperText={formik.touched.companyName && formik.errors.companyName}
                                disabled={!editable}
                            />
                        </div>

                        {canInteractWithAllFields && (
                            <>
                                <div className="mb-5">
                                    <TextField
                                        name="companyRegisterNo"
                                        label="Codul Registrului Comerțului"
                                        placeholder="ex: j22/3321/2017"
                                        value={formik.values.companyRegisterNo}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.companyRegisterNo && Boolean(formik.errors.companyRegisterNo)
                                        }
                                        helperText={formik.touched.companyRegisterNo && formik.errors.companyRegisterNo}
                                        disabled={!editable}
                                    />
                                </div>

                                <div className="mb-5">
                                    <TextField
                                        name="cui"
                                        label="CUI/CIF"
                                        placeholder="ex: RO31422664"
                                        value={formik.values.cui}
                                        onChange={formik.handleChange}
                                        error={formik.touched.cui && Boolean(formik.errors.cui)}
                                        helperText={formik.touched.cui && formik.errors.cui}
                                        disabled={!editable}
                                    />
                                </div>

                                <div className="mb-5">
                                    <TextField
                                        name="addressString"
                                        label="Adresă sediu social"
                                        placeholder="ex: județ, oraș, stradă, nr, etc"
                                        value={formik.values.addressString}
                                        onChange={formik.handleChange}
                                        error={formik.touched.addressString && Boolean(formik.errors.addressString)}
                                        helperText={formik.touched.addressString && formik.errors.addressString}
                                        disabled={!editable}
                                    />
                                </div>
                            </>
                        )}

                        <div className="mb-5">
                            <TextField
                                name="email"
                                label="Email"
                                placeholder="client@domeniu.com"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                disabled={client ? true : false}
                            />
                        </div>

                        {canInteractWithAllFields && (
                            <>
                                <div className="mb-5">
                                    <TextField
                                        name="phoneNo"
                                        label="Telefon"
                                        placeholder="0753 176 913"
                                        value={formik.values.phoneNo}
                                        onChange={formik.handleChange}
                                        error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
                                        helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                                        disabled={!editable}
                                    />
                                </div>

                                <div className="mb-5">
                                    <TextField
                                        name="administratorName"
                                        label="Nume asociat / administrator"
                                        placeholder="Nume Prenume"
                                        value={formik.values.administratorName}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.administratorName && Boolean(formik.errors.administratorName)
                                        }
                                        helperText={formik.touched.administratorName && formik.errors.administratorName}
                                        disabled={!editable}
                                    />
                                </div>

                                <div className="mb-5">
                                    <TextField
                                        name="bank"
                                        label="Bancă"
                                        placeholder="ex: Banca Transilvania"
                                        value={formik.values.bank}
                                        onChange={formik.handleChange}
                                        error={formik.touched.bank && Boolean(formik.errors.bank)}
                                        helperText={formik.touched.bank && formik.errors.bank}
                                        disabled={!editable}
                                    />
                                </div>

                                <div className="mb-5">
                                    <TextField
                                        name="iban"
                                        label="Cont IBAN"
                                        placeholder="ex: RO91BTRLRONCRT0421401234"
                                        value={formik.values.iban}
                                        onChange={formik.handleChange}
                                        error={formik.touched.iban && Boolean(formik.errors.iban)}
                                        helperText={formik.touched.iban && formik.errors.iban}
                                        disabled={!editable}
                                    />
                                </div>

                                <div className="mb-5">
                                    <TextField
                                        name="occupationDescription"
                                        label="Activitate firmă"
                                        placeholder="ex: Dezvoltator imobiliar"
                                        rows={3}
                                        multiline
                                        value={formik.values.occupationDescription}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.occupationDescription &&
                                            Boolean(formik.errors.occupationDescription)
                                        }
                                        helperText={
                                            formik.touched.occupationDescription && formik.errors.occupationDescription
                                        }
                                        disabled={!editable}
                                    />
                                </div>
                            </>
                        )}

                        {editable && canInteractWithAllFields && (
                            <>
                                <div className="mb-5">
                                    <LabelWrapper label="Logo companie">
                                        <FileUploadContainer onUpload={handleImageUpload}>
                                            <Button fullWidth startIcon={<PhotoIcon />}>
                                                {(() => {
                                                    if (client === null && logoBlob === null) {
                                                        return 'Adaugă logo companie';
                                                    } else if (client === null && logoBlob) {
                                                        return logoBlob?.name?.substr(0, 10) + '...';
                                                    } else if (client && logoBlob === null) {
                                                        return 'Schimbă logo companie';
                                                    } else {
                                                        return logoBlob?.name?.substr(0, 10) + '...';
                                                    }
                                                })()}
                                            </Button>
                                        </FileUploadContainer>
                                    </LabelWrapper>
                                </div>

                                <div className="mb-5">
                                    <LabelWrapper label="Icon">
                                        <FileUploadContainer onUpload={handleIconUpload}>
                                            <Button fullWidth startIcon={<PhotoIcon />}>
                                                {(() => {
                                                    if (client === null && iconBlob === null) {
                                                        return 'Adaugă icon companie';
                                                    } else if (client === null && iconBlob) {
                                                        return iconBlob?.name?.substr(0, 10) + '...';
                                                    } else if (client && iconBlob === null) {
                                                        return 'Schimbă icon companie';
                                                    } else {
                                                        return iconBlob?.name?.substr(0, 10) + '...';
                                                    }
                                                })()}
                                            </Button>
                                        </FileUploadContainer>
                                    </LabelWrapper>
                                </div>
                            </>
                        )}

                        {submitButton}
                    </div>

                    {!canInteractWithAllFields && !client && (
                        <div className="flex flex-col gap-16">
                            <div className="flex flex-col gap-5">
                                <h3>Detalii abonament</h3>
                                <LabelWrapper label="Data începere contract (și prima factură)">
                                    <DatePicker date={startDate} setDate={setStartDate} />
                                </LabelWrapper>

                                <LabelWrapper label="Tip abonament">
                                    <Dropdown
                                        selectedOption={subscriptionType}
                                        setSelectedOption={setSubscriptionType}
                                        // eslint-disable-next-line
                                        options={subscriptionTypes.map((st) => st.name.toUpperCase())}
                                    />
                                </LabelWrapper>
                            </div>

                            <div className="flex flex-col gap-5">
                                <h3>Completarea automată a clientului cu date</h3>
                                <div className={'flex items-center gap-4 rounded-md bg-layout-background p-2'}>
                                    <Toggle checked={isDummy} setChecked={setIsDummy} />
                                    <p>Activeză completarea automată a datelor</p>
                                </div>

                                {isDummy && (
                                    <LabelWrapper label="Tipul de date">
                                        <Dropdown
                                            selectedOption={dummyType}
                                            setSelectedOption={setDummyType}
                                            // eslint-disable-next-line
                                            options={dummyTypes.map((dt) => dummyTypesTranslations[dt])}
                                        />
                                    </LabelWrapper>
                                )}
                            </div>
                        </div>
                    )}
                </form>
            )}
        </>
    );
};

ClientDetailsForm.propTypes = {
    editable: PropTypes.bool,
    canInteractWithAllFields: PropTypes.bool,
    canDisplayTitle: PropTypes.bool,
    // eslint-disable-next-line
    client: PropTypes.object,
    // eslint-disable-next-line
    submitButton: PropTypes.element,
    onSuccess: PropTypes.func,
};

ClientDetailsForm.defaultProps = {
    editable: true,
    canInteractWithAllFields: false,
    canDisplayTitle: false,
    client: null,
    submitButton: null,
    onSuccess: () => null,
};

export default ClientDetailsForm;
