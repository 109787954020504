import { useSnackbar } from 'notistack';
import React, { cloneElement, useState } from 'react';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import PropTypes from 'prop-types';
import ThemeConfigurator from 'components/configurator/ThemeConfigurator';

const ClientConfigurator = ({
    client,
    backButton,
    submitButton,
    onSuccess,
    editable,
    configTabs,
    configActiveTab,
    route,
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [clientThemes, setClientThemes] = useState(client.ThemeConfigurator);
    const [canChangeTheme, setCanChangeTheme] = useState(client.canChangeTheme);
    const [activeThemeId, setActiveThemeId] = useState(client.activeThemeId);

    const submitConfiguration = async () => {
        const data = {
            themes: clientThemes,
            canChangeTheme,
            activeThemeId: canChangeTheme ? activeThemeId : clientThemes[0].id,
        };

        const reqBody = {
            id: client.id,
            data,
        };

        try {
            await API.post(route, reqBody);

            const callbackData = { ...data, ThemeConfigurator: data.themes };
            delete callbackData.themes;

            onSuccess?.(callbackData);
        } catch (err) {
            console.error('Could not submit CONFIGURATION!');
            console.error(err);
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        }
    };

    return (
        <div className="w-full">
            <div className="mb-16 flex flex-col gap-16">
                {configTabs[configActiveTab] === 'Configurator teme' && (
                    <ThemeConfigurator
                        clientThemes={clientThemes}
                        setClientThemes={setClientThemes}
                        canChangeTheme={canChangeTheme}
                        setCanChangeTheme={setCanChangeTheme}
                        activeThemeId={activeThemeId}
                        setActiveThemeId={setActiveThemeId}
                        submitConfiguration={submitConfiguration}
                        editable={editable}
                    />
                )}
            </div>

            {(submitButton || backButton) && (
                <div className="flex items-center gap-4">
                    {backButton}
                    {cloneElement(submitButton, { onClick: submitConfiguration })}
                </div>
            )}
        </div>
    );
};

ClientConfigurator.propTypes = {
    editable: PropTypes.bool,
    route: PropTypes.string,
    client: PropTypes.object,
    submitButton: PropTypes.element,
    backButton: PropTypes.element,
    onSuccess: PropTypes.func,
    configActiveTab: PropTypes.number,
    configTabs: PropTypes.array,
};
ClientConfigurator.defaultProps = {
    editable: true,
    route: '/customer_completion_process',
    client: null,
    submitButton: null,
    backButton: null,
    onSuccess: () => {},
    configActiveTab: 0,
    configTabs: [],
};

export default ClientConfigurator;
