import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from '../Toggle/Toggle';

export const CheckItem = (props) => {
    const { name, checked, setChecked, small } = props;

    return (
        // eslint-disable-next-line
        <div
            className={`bg-layout-background flex-shrink-0 rounded-md ${
                small ? 'p-1.5' : 'p-3'
            } hover:bg-layout-background-dark flex cursor-pointer items-center`}
            onClick={() => setChecked(!checked)}
        >
            <Toggle checked={checked} />
            <p className={`${small ? 'text-base font-normal' : 'text-lg font-bold'} ml-2 pr-2`}>{name}</p>
        </div>
    );
};

CheckItem.propTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    setChecked: PropTypes.func,
    small: PropTypes.bool,
};

CheckItem.defaultProps = {
    name: '',
    checked: false,
    setChecked: () => null,
    small: false,
};
