import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { PropTypes } from 'prop-types';

export const TableSeparator = ({ height }) => (
    <TableRow>
        <div className={`h-${height}`} />
    </TableRow>
);

TableSeparator.propTypes = {
    height: PropTypes.number,
};

TableSeparator.defaultProps = {
    height: 1,
};
