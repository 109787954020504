import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { clientRoutes } from 'routes'
import ViewClientsPage from 'components/pages/ClientPages/ViewClientsPage'
import AddClientPage from 'components/pages/ClientPages/AddClientPage'
import UpdateClientPage from 'components/pages/ClientPages/UpdateClientPage'

const Client = () => (
  <Switch>

    <Route exact path={clientRoutes.create}>
      <AddClientPage />
    </Route>

    <Route exact path={clientRoutes.read}>
      <ViewClientsPage />
    </Route>

    <Route exact path={clientRoutes.update}>
      <UpdateClientPage />
    </Route>

  </Switch>
)

export default Client
