import React from 'react';
import { MemoizedAppWrapper } from 'RaisisComponents';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { baseRoute } from 'routes';
import App from './App';
import './index.css';

const queryClient = new QueryClient();
const root = document.getElementById('root');

render(
    <React.StrictMode>
        <BrowserRouter basename={baseRoute}>
            <QueryClientProvider client={queryClient}>
                <MemoizedAppWrapper>
                    <App />
                </MemoizedAppWrapper>
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>,
    root,
);
