import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

const iconStyle = {
    marginRight: '1rem',
    color: '#fff',
    fontSize: '4rem',
    flexShrink: 0,
};

export const Login = (props) => {
    const { platform, loading, username, setUsername, password, setPassword, onSubmit, rememberMe, setRememberMe } =
        props;

    return (
        <form className="relative flex h-screen w-full items-center justify-center" onSubmit={onSubmit}>
            <div className="w-full max-w-sm -translate-y-12 transform">
                <div className="mb-12 flex items-center">
                    <FingerprintIcon style={iconStyle} />
                    <h1 className="font-medium uppercase text-white">{platform}</h1>
                </div>

                <div className="mb-10 w-full">
                    <div className="mb-5">
                        <TextField
                            label="Utilizator"
                            data-testid="user-data"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <TextField
                        type="password"
                        label="Parolă"
                        data-testid="password-data"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                <div className="mb-5 flex items-center justify-between">
                    <Button
                        type="submit"
                        fullWidth
                        color="primary"
                        style={{
                            pointerEvents: loading ? 'none' : 'all',
                        }}
                    >
                        Conecteză-te
                    </Button>

                    <div className="w-5" />

                    <Button fullWidth variant="text">
                        Recuperare parolă
                    </Button>
                </div>

                <FormControlLabel
                    control={
                        <Checkbox
                            style={{
                                color: '#328CF8',
                            }}
                            color="primary"
                            checked={rememberMe}
                            onChange={setRememberMe}
                        />
                    }
                    label={<p className="pointer-events-none text-white">Rămâi conectat</p>}
                />
            </div>
        </form>
    );
};

Login.propTypes = {
    platform: PropTypes.string,
    loading: PropTypes.bool,
    username: PropTypes.string,
    setUsername: PropTypes.func,
    password: PropTypes.string,
    setPassword: PropTypes.func,
    onSubmit: PropTypes.func,
    rememberMe: PropTypes.bool,
    setRememberMe: PropTypes.func,
};

Login.defaultProps = {
    platform: '',
    loading: false,
    username: '',
    setUsername: () => null,
    password: '',
    setPassword: () => null,
    onSubmit: () => null,
    rememberMe: true,
    setRememberMe: () => null,
};
