import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export const Tags = (props) => {
    const { tags, setTags, allTags } = props;

    const onDelete = (val) => {
        setTags(tags.filter((tag) => tag.value !== val));
    };

    return (
        <div className="no-user-select-recursive relative inline-flex flex-wrap items-start">
            {tags.map((tag) => (
                <Tag key={tag.id} name={tag.name} value={tag.value} onDelete={onDelete} />
            ))}

            <AddTag tags={tags} setTags={setTags} allTags={allTags} />
        </div>
    );
};
Tags.propTypes = {
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            // eslint-disable-next-line
            value: PropTypes.any,
        })
    ),
    // eslint-disable-next-line
    allTags: PropTypes.arrayOf(PropTypes.any),
    setTags: PropTypes.func,
};
Tags.defaultProps = {
    tags: [],
    setTags: () => null,
    allTags: [],
};

const Tag = (props) => {
    const { name, value, onDelete } = props;

    return (
        <div className="bg-layout-background group relative mb-1 mr-1 flex h-10 cursor-pointer items-center justify-center rounded-md px-5 text-white transition-colors hover:bg-primary-transparent">
            <p className="transform transition-transform group-hover:-translate-x-2">{name}</p>
            <CloseIcon
                onClick={() => onDelete(value)}
                className="absolute right-1.5 top-1/2 w-4 -translate-y-1/2 transform text-primary-main opacity-0 transition-all hover:text-primary-light group-hover:opacity-100"
            />
        </div>
    );
};
Tag.propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    // eslint-disable-next-line
    value: PropTypes.any,
    onDelete: PropTypes.func,
};
Tag.defaultProps = {
    name: null,
    value: null,
    onDelete: () => null,
};

const AddTag = (props) => {
    const [open, setOpen] = useState(false);
    const { tags, setTags, allTags } = props;

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            {/* eslint-disable-next-line */}
            <div
                onClick={() => setOpen(!open)}
                className="bg-layout-background relative mb-1 flex h-10 w-10 cursor-pointer items-center justify-center rounded-md text-white transition-colors hover:bg-primary-transparent"
            >
                <AddIcon />
                {open ? (
                    <ul className="absolute left-0 top-full z-50 min-w-full rounded-md bg-primary-light py-2">
                        {allTags.map((tag) => {
                            const isSelected = tags.filter((t) => JSON.stringify(t) === JSON.stringify(tag)).length;

                            return (
                                // eslint-disable-next-line
                                <li
                                    key={tag.name}
                                    className="flex items-center p-2 pr-6 transition-colors hover:bg-primary-main"
                                    onClick={() =>
                                        isSelected
                                            ? setTags(tags.filter((t) => JSON.stringify(t) !== JSON.stringify(tag)))
                                            : setTags([...tags, tag])
                                    }
                                >
                                    {isSelected ? (
                                        <CheckBoxIcon style={{ fontSize: '1.20rem' }} />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon style={{ fontSize: '1.20rem' }} />
                                    )}
                                    <p className="ml-2 whitespace-nowrap" style={{ userSelect: 'none' }}>
                                        {tag.name}
                                    </p>
                                </li>
                            );
                        })}
                    </ul>
                ) : null}
            </div>
        </ClickAwayListener>
    );
};
AddTag.propTypes = {
    // eslint-disable-next-line
    tags: PropTypes.array,
    setTags: PropTypes.func,
    // eslint-disable-next-line
    allTags: PropTypes.array,
};
AddTag.defaultProps = {
    tags: [],
    setTags: () => null,
    allTags: [],
};
