import React, { useState, useEffect } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(() => ({
    mainTr: {
        position: 'relative',
        '& > td': {
            position: 'relative',
            zIndex: 10,
        },
    },
    tr: {
        position: 'relative',
        border: 'none',
        '&:hover': {
            background: 'transparent',
        },
    },
    td: {
        position: 'relative',
        padding: 0,
        zIndex: 10,
    },
    collapse: {
        padding: '10px',
    },
    collapseNoPadding: {
        padding: 0,
    },
    flipped: {
        transform: 'rotate(180deg)',
    },
}));

export const ExpandableRow = (props) => {
    const classes = useStyles();
    const { colSpan, row, collapse, padding, displayDataOnOpen } = props;
    const [delayedCollapse, setDelayedCollapse] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (displayDataOnOpen && open) setDelayedCollapse(collapse);
        else setDelayedCollapse(null);
    }, [displayDataOnOpen, open]);

    return (
        <>
            <TableRow className={classes.mainTr} onClick={() => setOpen(!open)}>
                {row}
                <TableCell align="right">
                    <KeyboardArrowDownIcon className={open ? classes.flipped : null} />
                </TableCell>

                {open && (
                    <div
                        className="bg-layout-background absolute left-0 top-0 z-0 h-full w-full"
                        style={{ borderTopRightRadius: '5px', borderTopLeftRadius: '5px' }}
                    />
                )}
            </TableRow>

            <TableRow className={classes.tr}>
                <TableCell colSpan={colSpan + 1} className={classes.td}>
                    <Collapse in={open}>
                        {displayDataOnOpen ? (
                            <>
                                {delayedCollapse !== null && (
                                    <div className={padding ? classes.collapse : classes.collapseNoPadding}>
                                        {delayedCollapse}
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className={padding ? classes.collapse : classes.collapseNoPadding}>{collapse}</div>
                        )}
                    </Collapse>
                </TableCell>

                <div
                    className="bg-layout-background absolute left-0 top-0 z-0 h-full w-full"
                    style={{ borderBottomRightRadius: '5px', borderBottomLeftRadius: '5px' }}
                />
            </TableRow>
        </>
    );
};

ExpandableRow.propTypes = {
    colSpan: PropTypes.number,
    row: PropTypes.element,
    collapse: PropTypes.element,
    padding: PropTypes.bool,
    displayDataOnOpen: PropTypes.bool,
};

ExpandableRow.defaultProps = {
    colSpan: 0,
    row: null,
    collapse: null,
    padding: true,
    displayDataOnOpen: false,
};
