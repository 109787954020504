import React from 'react';
import PropTypes from 'prop-types';

export const ActionButton = (props) => {
    const { onClick, icon, color, disabled, sizes } = props;

    return (
        // eslint-disable-next-line
        <div
            className={`ACTION-BUTTON relative inline-flex flex-shrink-0 items-center justify-center font-thin text-white w-${
                sizes.width
            } h-${sizes.height} no-user-select-recursive cursor-pointer rounded-full ${
                color === '' && 'bg-primary-main'
            } ${disabled && 'pointer-events-none'}`}
            onClick={onClick}
            style={
                color !== ''
                    ? {
                          backgroundColor: disabled ? 'rgb(175 175 175)' : `${color}`,
                      }
                    : {}
            }
        >
            {icon}
        </div>
    );
};
ActionButton.propTypes = {
    icon: PropTypes.element,
    onClick: PropTypes.func,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    sizes: PropTypes.object,
};
ActionButton.defaultProps = {
    icon: null,
    onClick: () => null,
    color: '',
    disabled: false,
    sizes: { width: 7, height: 7 },
};
