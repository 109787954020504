import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import useScreenSizes from 'hooks/useScreenSizes.js';

export const Stepper = (props) => {
    const { step, setStep, steps } = props;
    const stepperContainer = useRef(null);
    const [width] = useScreenSizes();

    useEffect(() => {
        if (!stepperContainer.current) return;

        const wrapper = stepperContainer.current;
        const wrapperWidth = wrapper.clientWidth;

        const container = wrapper.firstChild;

        const childrenWidths = Array.from(container.children).map((child) => child.clientWidth);
        const leftOffset = childrenWidths.slice(0, step + 1).reduce((sum, curr) => (sum += curr), 0);

        if (leftOffset > wrapperWidth) {
            container.style.transform = `translateX(-${leftOffset - wrapperWidth}px)`;
            container.style.transition = `0.2s ease-in-out`;
        } else if (leftOffset <= wrapperWidth) {
            container.style.transform = `translateX(0px)`;
            container.style.transition = `0.2s ease-in-out`;
        }
    }, [step, width]);

    return (
        <div className="w-full" ref={stepperContainer}>
            <div className="inline-flex items-stretch justify-start">
                {steps.map((s, index) => (
                    // eslint-disable-next-line
                    <div
                        className={`first:rounded-tl-md first:rounded-bl-md px-6 py-3 text-sm font-semibold duration-200 border-2 transition-all flex-shrink-0 ${
                            index === step ? 'rounded-tr-md rounded-br-md border-primary-main' : 'border-transparent'
                        }  ${index <= step ? 'border-primary-main' : 'border-transparent'} ${
                            index < step
                                ? 'bg-primary-main hover:bg-primary-light hover:border-primary-light'
                                : 'border-transparent'
                        } cursor-pointer`}
                        style={{
                            color: index <= step ? 'white' : 'rgba(255, 255, 255, .25)',
                            userSelect: 'none',
                        }}
                        onClick={() => setStep(index)}
                        // eslint-disable-next-line
                        key={index}
                    >
                        {`${index + 1}. ${s}`}
                    </div>
                ))}
            </div>
        </div>
    );
};

Stepper.propTypes = {
    step: PropTypes.number,
    setStep: PropTypes.func,
    steps: PropTypes.arrayOf(PropTypes.string),
};

Stepper.defaultProps = {
    step: 0,
    setStep: () => null,
    steps: [],
};
