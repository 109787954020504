import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import './search.scss';

export const Search = (props) => {
    const { value, setValue, light, searchIconBig } = props;
    const isSearch = value.trim().length > 0;

    const handleRemoveSearch = () => setValue('');

    return (
        <div className="search-input-container">
            {/* Search icon */}
            <div className="absolute left-2 top-1/2 flex -translate-y-1/2 transform">
                <SearchIcon className={`self-center ${searchIconBig ? 'search-icon-big' : 'search-icon'}`} />
            </div>

            {/* Input */}
            <input
                className={`outline-none rounded-full py-2 pl-12 pr-12 font-light text-white  ${
                    light ? 'border-white bg-transparent' : 'bg-layout-background border-layout-background-dark'
                } ${searchIconBig ? 'text-xl' : ''}`}
                name="search"
                placeholder={'caută...'}
                value={value}
                autoComplete="off"
                onChange={(e) => setValue(e.target.value)}
            />

            {/* Close icon */}
            {isSearch && (
                <div
                    className="absolute right-2 top-1/2 flex -translate-y-1/2 transform cursor-pointer self-center"
                    onClick={handleRemoveSearch}
                >
                    <CloseIcon className={`self-center ${searchIconBig ? 'search-icon-big' : 'search-icon'}`} />
                </div>
            )}
        </div>
    );
};
Search.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    light: PropTypes.bool,
    searchIconBig: PropTypes.bool,
};
Search.defaultProps = {
    value: '',
    setValue: () => null,
    light: false,
    searchIconBig: false,
};
