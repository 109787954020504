import API from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

export const generateUUID = () => crypto.randomUUID?.() ?? uuidv4();

// eslint-disable-next-line
export const fingerPrintIconStyle = {
    fontSize: '2.15rem',
};

// eslint-disable-next-line
export const EMAIL_REGEX =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// eslint-disable-next-line
export const IBAN_REGEX =
    // eslint-disable-next-line
    /\b[A-Z]{2}\d{2}[A-Z0-9]{1,30}\b/i;

// eslint-disable-next-line
export const PHONE_NO_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const SUBDOMAIN_REGEX = /^[a-zA-Z0-9-]+$/;

// eslint-disable-next-line
export const PASSWORD_REGEX = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%*]).{8,40})/;

// eslint-disable-next-line
export const errorMessages = {
    string: 'Câmpul este obligatoriu!',
    required: 'Câmpul este obligatoriu!',
    min: (i) => `Câmpul trebuie să conțină cel puțin ${i} caractere!`,
    email: 'Nu este e-mail valid!',
    phone: 'Nu este un număr de telefon valid!',
    iban: 'Nu este IBAN valid!',
};

// eslint-disable-next-line
export const whoAmI = async () => {
    try {
        const res = await API.get('me');
        return res.data.data;
    } catch {
        return null;
    }
};

// eslint-disable-next-line
export const logout = (setUser = () => null, callback = () => null) => {
    API.defaults.headers.common.Authorization = '';
    localStorage.removeItem('accessToken');
    setUser(null);
    callback();
};

// File Upload
export const uploadSingleFile = async (e, callback) => {
    const file = e.target.files[0];
    if (file.size / 1024 / 1024 > 2) {
        callback({
            message: 'Fișierul depășește limita de 2MB!',
            blob: null,
            info: null,
        });
    } else {
        if (['image/jpeg', 'image/png', 'application/pdf'].find((type) => type === file.type)) {
            try {
                callback({
                    message: null,
                    blob: file,
                    info: {
                        name: file.name,
                    },
                });
            } catch (err) {
                console.error('Nu s-a putut încărca fișierul de la:', e.target);
                console.error(err);
                callback({
                    message: 'Ceva nu a mers bine! Vă rugăm să încercați din nou!',
                    blob: null,
                    info: null,
                });
            }
        } else {
            callback({
                message: 'Tipul de fișier este greșit! Doar fișierele de tip JPEG,PNG și PDF sunt acceptate!',
                blob: null,
                info: null,
            });
        }
    }
};

// eslint-disable-next-line
export const errorHandling = (err) => {
    if ('errors' in err) {
        return err.errors[0];
    } else if (err.response.status === 400) {
        return err.response.data.message;
        // eslint-disable-next-line
    } else if (err.response.status === 500) {
        return 'error-message';
    }
};

const monthMapper = ['IAN', 'FEB', 'MAR', 'APR', 'MAI', 'IUN', 'IUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

// eslint-disable-next-line
export const formatDate = (date, withYear = true) => {
    const jsDate = new Date(date);
    // eslint-disable-next-line
    if (withYear) return `${jsDate.getDate()} ${monthMapper[jsDate.getMonth()]} ${jsDate.getFullYear()}`;
    // eslint-disable-next-line
    else return `${jsDate.getDate()} ${monthMapper[jsDate.getMonth()]}`;
};

export const rgbToHex = (r, g, b) => {
    return '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
};

export const hexToRgb = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (r, g, b) {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(result[3], 16)}` : null;
};

// ? Represents a theme object used to in case the tenant has no themes added to it
export const defaultTheme = {
    id: generateUUID(),
    name: 'Business (default)',
    theme: JSON.stringify({
        'UI-COLORS': {
            'MAIN-PRIMARY': { varName: '--base-primary-main', color: '8 51 105' },
            'LIGHT-PRIMARY': { varName: '--base-primary-light', color: '17 81 139' },
            'DARK-PRIMARY': { varName: '--base-primary-dark', color: '1 29 80' },
            'MAIN-SECONDARY': { varName: '--base-secondary-main', color: '156 124 53' },
            'LIGHT-SECONDARY': { varName: '--base-secondary-light', color: '199 163 83' },
            'LIGHTER-SECONDARY': { varName: '--base-secondary-lighter', color: '216 191 135' },
            'GRADIENT-PRIMARY-LIGHT': { varName: '--base-gradient-primary-light', color: '17 81 139' },
            'GRADIENT-PRIMARY-DARK': { varName: '--base-gradient-primary-dark', color: '1 29 80' },
            'GRADIENT-SIDEBAR-MAIN': { varName: '--base-gradient-sidebar-main', color: '5 41 94' },
            'GRADIENT-SIDEBAR-LIGHT': { varName: '--base-gradient-sidebar-light', color: '8 51 105' },
            'GRADIENT-SIDEBAR-DARK': { varName: '--base-gradient-sidebar-dark', color: '1 29 80' },
        },
        'LAYOUT-COLORS': {
            'MAIN-LAYOUT': { varName: '--base-layout-main', color: '20 20 20' },
            'LIGHT-LAYOUT': { varName: '--base-layout-light', color: '32 32 32' },
            'LIGHTER-LAYOUT': { varName: '--base-layout-lighter', color: '56 56 56' },
            'TRANSPARENT-LAYOUT': { varName: '--base-layout-transparent', color: '216 232 255' },
        },
        'STATE-COLORS': {
            ERROR: { varName: '--base-error', color: '255 105 105' },
            SUCCESS: { varName: '--base-success', color: '55 169 133' },
            WARNING: { varName: '--base-warning', color: '245 158 11' },
            DISABLED: { varName: '--base-disabled', color: '175 175 175' },
        },
        'TEXT-COLORS': {
            'MAIN-TEXT': { varName: '--base-main-text', color: '255 255 255' },
            'SECONDARY-TEXT': { varName: '--base-secondary-text', color: '156 124 53' },
            'DARK-TEXT': { varName: '--base-dark-text', color: '215 215 215' },
            'SIDEBAR-TEXT': { varName: '--base-sidebar-text', color: '255 255 255' },
            'BUTTONS-TEXT': { varName: '--base-buttons-text', color: '255 255 255' },
            'GRADIENT-TEXT': { varName: '--base-gradient-text', color: '255 255 255' },
        },
        'PDF-COLORS': {
            'PDF-TEXT': { varName: '--base-pdf-text', color: '0 0 0' },
            'PDF-LINK-TEXT': { varName: '--base-pdf-link-text', color: '45 122 255' },
            'PDF-LAYOUT-TEXT': { varName: '--base-pdf-layout-text', color: '255 255 255' },
            'PDF-LAYOUT-BACKGROUND': { varName: '--base-pdf-layout-background', color: '8 51 105' },
        },
    }),
};
