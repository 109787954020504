import React, { useState, useEffect, useRef, useLayoutEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useScreenSizes from 'hooks/useScreenSizes.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as Logo } from './logo.svg';

export const NewSidebar = (props) => {
    const {
        logoAction,
        userDropdownActions,
        imageUrl,
        userName,
        footer,
        moduleName,
        backHandle,
        navItems,
        selectedItem,
        setSelectedItem,
        selectedSubItem,
        setSelectedSubItem,
    } = props;

    /**
     * Controls wheather the user dropdown is opened or not
     */
    const [userDropdown, setUserDropdown] = useState(false);

    const [openMenu, setOpenMenu] = useState(false);

    const [width, height] = useScreenSizes();

    const innerSidebarRef = useRef();
    const userDropDownMobileRef = useRef();

    const [elementHeight, setElementHeight] = useState(0);

    const displayMobileNavHandler = () => {
        setOpenMenu((prev) => !prev);
        setUserDropdown(false);
    };

    useEffect(() => {
        setSelectedItem(-1);
        setSelectedSubItem(-1);
    }, [navItems]);

    useEffect(() => {
        if (width > 750) {
            setOpenMenu(false);
            setUserDropdown(false);
            innerSidebarRef.current.style.overflow = 'hidden';
            return;
        }

        if (innerSidebarRef.current.scrollHeight > innerSidebarRef.current.clientHeight) {
            innerSidebarRef.current.style.overflow = 'auto';
        } else {
            innerSidebarRef.current.style.overflow = 'visible';
        }
    }, [width, height]);

    useLayoutEffect(() => {
        setElementHeight(userDropDownMobileRef.current?.scrollHeight);
    }, [width, openMenu]);

    return (
        <div className={`main-sidebar ${openMenu ? 'main-sidebar-open' : ''}`}>
            <div className={`inner-sidebar ${openMenu ? 'gap-10' : ''}`} ref={innerSidebarRef}>
                {/*
            Upper sidebar section
            Logo, User Avatar, User Dropdown
         */}
                <div className="relative z-20 flex w-full flex-shrink-0 flex-col items-start py-5 pl-5  md:mb-3 md:mt-3 md:flex-row md:items-center md:justify-between md:py-0 md:pl-0">
                    {/* Logo */}
                    {/* eslint-disable-next-line */}
                    <div
                        className="mb-5 flex w-full cursor-pointer items-center justify-start opacity-75 transition-opacity hover:opacity-100 md:mb-0 md:w-auto md:opacity-100"
                        onClick={logoAction}
                    >
                        <div className="relative mr-5 h-10 w-10 flex-shrink-0 ">
                            <Logo className="absolute left-1/2 top-1/2 w-10 flex-shrink-0 -translate-x-1/2 -translate-y-1/2 transform" />
                        </div>
                        <p className="appear-on-expand user-select-none flex-shrink-0 text-lg font-medium uppercase text-white ">
                            M2M ERP
                        </p>
                    </div>

                    {/* User */}
                    {/* eslint-disable-next-line */}
                    <div
                        className="relative flex  w-full  cursor-pointer items-center opacity-75 transition-opacity hover:opacity-100 md:hidden "
                        // onClick={() => setUserDropdown(true)}
                        // onMouseLeave={() => setUserDropdown(false)}
                    >
                        <div
                            className="mr-5 h-10 w-10 flex-shrink-0 rounded-full bg-blue-300 bg-cover bg-center"
                            style={
                                imageUrl !== '' && {
                                    backgroundImage: `url('${imageUrl}')`,
                                }
                            }
                        />
                        <p className="appear-on-expand relative w-full flex-shrink-0 text-lg font-medium text-white md:hidden">
                            {userName}
                        </p>
                        {userDropdownActions.length > 0 && (
                            <div
                                className={`absolute left-12 top-full transform overflow-hidden rounded-md bg-primary-light transition-all ${
                                    userDropdown
                                        ? 'pointer-events-auto translate-x-0 opacity-100'
                                        : 'pointer-events-none translate-x-5 opacity-0'
                                }`}
                            >
                                <ul>
                                    {/* eslint-disable-next-line */}
                                    <li
                                        className="whitespace-nowrap px-8 py-3 text-white hover:bg-layout-background"
                                        onClick={() => userDropdownActions[0].handle()}
                                    >
                                        {userDropdownActions[0].name}
                                    </li>
                                    {/* eslint-disable-next-line */}
                                    {/* <li
                                        className="hover:bg-layout-background whitespace-nowrap px-8 py-3 text-white"
                                        onClick={() => userDropdownActions[1].handle()}
                                    >
                                        {userDropdownActions[1].name}
                                    </li> */}
                                    {/* eslint-disable-next-line */}
                                    {/* <li
                                        className="hover:bg-layout-background flex items-center whitespace-nowrap px-8 py-3 text-white"
                                        style={{
                                            borderTop: '1px solid rgba(255, 255, 255, .25)',
                                        }}
                                        onClick={() => userDropdownActions[2].handle()}
                                    >
                                        <ExitToAppIcon className="mr-3" />
                                        {userDropdownActions[2].name}
                                    </li> */}
                                </ul>
                            </div>
                        )}
                    </div>

                    {/* User mobile */}
                    <div
                        className={`absolute left-1/2 top-1/2 hidden -translate-x-1/2 -translate-y-1/2 transform cursor-pointer items-center justify-center md:flex`}
                        onClick={() => {
                            setOpenMenu(true);
                            setUserDropdown((prev) => !prev);
                        }}
                    >
                        <div
                            className="mr-5 h-10 w-10 flex-shrink-0 rounded-full bg-blue-300 bg-cover bg-center"
                            style={
                                imageUrl !== '' && {
                                    backgroundImage: `url('${imageUrl}')`,
                                }
                            }
                        />
                        <p className="appear-on-expand relative w-auto flex-shrink-0 text-lg font-medium text-white">
                            {userName.length > 15 ? userName.slice(0, 15) + '...' : userName}
                        </p>
                        <div
                            className={` ml-2 mt-1 transition-all duration-200 ${
                                userDropdown ? 'rotate-180 transform' : ''
                            }`}
                        >
                            <ExpandMoreIcon className="text-white transition-all duration-200" />
                        </div>
                    </div>

                    {/* Mobile navigation toggle */}
                    <div
                        className="mr-5 hidden cursor-pointer flex-col gap-1 md:flex"
                        onClick={displayMobileNavHandler}
                    >
                        <span
                            className={`inline-block h-1 w-8 rounded-sm bg-white transition-all duration-300 ${
                                openMenu ? 'translate-y-2 rotate-45 transform' : ''
                            }`}
                        />
                        <span className={`inline-block h-1 w-8 rounded-sm bg-white ${openMenu ? 'opacity-0' : ''}`} />
                        <span
                            className={`inline-block h-1 w-8 rounded-sm bg-white transition-all duration-300 ${
                                openMenu ? '-translate-y-2 -rotate-45 transform' : ''
                            }`}
                        />
                    </div>
                </div>

                {/* Mobile user options */}
                <div
                    className={`sidebar-content  hidden w-full flex-col items-center justify-center gap-6 ${
                        openMenu ? 'delay-sidebar-content md:flex' : 'md:hidden'
                    }`}
                >
                    {userDropdownActions.length > 0 && (
                        <ul
                            className={`flex items-center transition-all duration-200 ${
                                width < 450 ? 'flex-col' : ''
                            } ${userDropdown ? '!visible opacity-100' : 'invisible max-h-0 opacity-0'}`}
                            style={{ maxHeight: userDropdown ? `${elementHeight}px` : '' }}
                            ref={userDropDownMobileRef}
                        >
                            {/* eslint-disable-next-line */}
                            <li
                                className="cursor-pointer whitespace-nowrap px-8 py-3 text-lg text-white hover:bg-layout-background"
                                onClick={() => {
                                    userDropdownActions[0].handle();
                                    setUserDropdown(false);
                                    setOpenMenu(false);
                                }}
                            >
                                {userDropdownActions[0].name}
                            </li>
                            {/* eslint-disable-next-line */}
                            {/* <li
                                className="cursor-pointer whitespace-nowrap px-8 py-3 text-lg text-white hover:bg-layout-background"
                                style={{
                                    borderLeft: width >= 450 && '1px solid rgba(255, 255, 255, .25)',
                                    borderTop: width < 450 && '1px solid rgba(255, 255, 255, .25)',
                                }}
                                onClick={() => {
                                    userDropdownActions[1].handle();
                                    setUserDropdown(false);
                                    setOpenMenu(false);
                                }}
                            >
                                {userDropdownActions[1].name}
                            </li> */}
                            {/* eslint-disable-next-line */}
                            {/* <li
                                className="flex cursor-pointer items-center whitespace-nowrap px-8 py-3 text-lg text-white hover:bg-layout-background"
                                style={{
                                    borderLeft: width >= 450 && '1px solid rgba(255, 255, 255, .25)',
                                    borderTop: width < 450 && '1px solid rgba(255, 255, 255, .25)',
                                }}
                                onClick={() => {
                                    userDropdownActions[2].handle();
                                    setUserDropdown(false);
                                    setOpenMenu(false);
                                }}
                            >
                                <ExitToAppIcon className="mr-3" />
                                {userDropdownActions[2].name}
                            </li> */}
                        </ul>
                    )}
                </div>

                {/* NAV */}
                <div
                    className={`sidebar-content relative z-10 flex flex-grow flex-col  ${
                        openMenu ? 'delay-sidebar-content justify-start md:flex' : 'justify-center md:hidden'
                    }`}
                >
                    {/* Platform */}
                    {moduleName.length > 0 && (
                        <div className="flex items-center justify-start pb-5 pl-5">
                            {backHandle != null && (
                                // eslint-disable-next-line
                                <div
                                    className="group flex w-10 flex-shrink-0 cursor-pointer items-center justify-center text-center"
                                    onClick={backHandle}
                                >
                                    <ArrowBackIcon className="text-md transform text-white transition-transform duration-300 group-hover:-translate-x-1" />
                                </div>
                            )}
                            <h1
                                className={`appear-on-expand whitespace-nowrap text-2xl font-bold uppercase tracking-widest text-white ${
                                    backHandle != null && 'pl-5'
                                }`}
                            >
                                {moduleName.length > 18 ? `${moduleName.slice(0, 18)}...` : moduleName}
                            </h1>
                        </div>
                    )}

                    {/* Nav Items */}
                    <ul
                        className="nav-list"
                        style={{
                            maxHeight: '60vh',
                        }}
                    >
                        {navItems.map((item, index) => {
                            const liClassName = ['', ''];
                            liClassName[0] = 'relative group w-full text-white bg-white';
                            liClassName[1] = selectedItem === index ? 'bg-opacity-5' : 'bg-opacity-0';

                            return (
                                <div key={item.name}>
                                    {/* eslint-disable-next-line */}
                                    <li
                                        className={liClassName.join(' ')}
                                        style={{
                                            borderBottom: '1px solid #4a8eff',
                                            borderTop: index === 0 ? '1px solid #4a8eff' : '',
                                        }}
                                        onClick={() => {
                                            if ('onRoute' in item) {
                                                setOpenMenu(false);
                                                setUserDropdown(false);
                                                item.onRoute();
                                                return;
                                            }

                                            if (selectedItem === index) {
                                                setSelectedItem(-1);
                                                setSelectedSubItem(-1);
                                            } else {
                                                setSelectedItem(index);
                                                setSelectedSubItem(-1);
                                            }
                                        }}
                                    >
                                        <div className="flex cursor-pointer items-center justify-between px-5 py-5 opacity-75 transition-opacity group-hover:opacity-100">
                                            <div className="flex items-center justify-start">
                                                <div className="relative">
                                                    {item.icon}
                                                    <div
                                                        className={`absolute left-1/2 top-11 h-1 -translate-x-1/2 transform rounded-md bg-white bg-opacity-20 transition-all ${
                                                            selectedItem === index ? 'w-10' : 'w-0'
                                                        }`}
                                                    />
                                                </div>
                                                <p
                                                    className="appear-on-expand ml-3 flex h-8 items-center whitespace-nowrap font-medium"
                                                    style={{ lineHeight: 1.2, fontWeight: 600 }}
                                                >
                                                    {item.name}
                                                </p>
                                            </div>

                                            {!('onRoute' in item) && (
                                                <DownArrowIcon
                                                    className={`transform transition-transform ${
                                                        selectedItem === index ? 'rotate-180' : 'rotate-0'
                                                    }`}
                                                />
                                            )}
                                        </div>

                                        {/* If selected then sub items appear HERE */}
                                        {'subItems' in item && selectedItem === index && (
                                            <div className="py-2.5 pl-14">
                                                {item.subItems.map((subItem, subIndex) => {
                                                    return (
                                                        // eslint-disable-next-line
                                                        <Fragment key={subItem.name}>
                                                            <div
                                                                className="flex cursor-pointer items-center py-2 opacity-75 transition-opacity hover:opacity-100"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();

                                                                    if ('onRoute' in subItem) {
                                                                        setOpenMenu(false);
                                                                        setUserDropdown(false);
                                                                        subItem.onRoute();
                                                                        return;
                                                                    }

                                                                    if (selectedSubItem === subIndex)
                                                                        setSelectedSubItem(-1);
                                                                    else setSelectedSubItem(subIndex);
                                                                }}
                                                            >
                                                                <span className="move-on-expand">{subItem.icon}</span>
                                                                {/* eslint-disable-next-line */}
                                                                <p
                                                                    className="appear-on-expand ml-3 flex h-8 items-center whitespace-nowrap font-medium"
                                                                    style={{ lineHeight: 1.2, fontWeight: 600 }}
                                                                >
                                                                    {subItem.name}
                                                                </p>

                                                                {!('onRoute' in subItem) && (
                                                                    <DownArrowIcon
                                                                        className={`ml-auto mr-5 transform transition-transform ${
                                                                            selectedSubItem === subIndex
                                                                                ? 'rotate-180'
                                                                                : 'rotate-0'
                                                                        }`}
                                                                    />
                                                                )}
                                                            </div>

                                                            {/* If selected then sub items appear HERE */}
                                                            {'subItems' in subItem && selectedSubItem === subIndex && (
                                                                <div className="py-2.5 pl-7">
                                                                    {subItem.subItems.map((childItem) => {
                                                                        return (
                                                                            // eslint-disable-next-line

                                                                            <div
                                                                                key={childItem.name}
                                                                                className="flex cursor-pointer items-center py-2 opacity-75 transition-opacity hover:opacity-100"
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setOpenMenu(false);
                                                                                    setUserDropdown(false);
                                                                                    childItem.onRoute();
                                                                                }}
                                                                            >
                                                                                <span className="move-child-on-expand">
                                                                                    {childItem.icon}
                                                                                </span>
                                                                                {/* eslint-disable-next-line */}
                                                                                <p
                                                                                    className="appear-on-expand ml-3 flex h-8 items-center whitespace-nowrap font-medium"
                                                                                    style={{
                                                                                        lineHeight: 1.2,
                                                                                        fontWeight: 600,
                                                                                    }}
                                                                                >
                                                                                    {childItem.name}
                                                                                </p>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            )}
                                                        </Fragment>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </li>
                                </div>
                            );
                        })}
                    </ul>
                </div>

                {/* Footer */}
                <div
                    className={`sidebar-content z-50 mb-20 flex w-full md:justify-center  ${
                        openMenu ? 'delay-sidebar-content md:flex' : 'md:hidden'
                    }`}
                >
                    {footer}
                </div>
            </div>
        </div>
    );
};

NewSidebar.propTypes = {
    logoAction: PropTypes.func,
    userName: PropTypes.string,
    imageUrl: PropTypes.string,
    userDropdownActions: PropTypes.arrayOf(PropTypes.object),
    footer: PropTypes.element,
    navItems: PropTypes.arrayOf(PropTypes.object),
    selectedItem: PropTypes.number,
    setSelectedItem: PropTypes.func,
    selectedSubItem: PropTypes.number,
    setSelectedSubItem: PropTypes.func,
    moduleName: PropTypes.string,
    backHandle: PropTypes.func,
};

NewSidebar.defaultProps = {
    logoAction: () => null,
    userName: '',
    imageUrl: '',
    userDropdownActions: [],
    footer: <></>,
    navItems: [],
    selectedItem: -1,
    setSelectedItem: () => null,
    moduleName: '',
    backHandle: () => null,
    selectedSubItem: null,
    setSelectedSubItem: () => {},
};
