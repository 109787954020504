import React from 'react';
import { mainRoutes } from 'routes';
// import HomePage from 'components/pages/HomePages/HomePage';
import LoginPage from 'components/pages/HomePages/LoginPage';
import { Route, Switch } from 'react-router-dom';

const Home = () => (
    <Switch>
        {/* <Route exact path={mainRoutes.home}>
            <HomePage />
        </Route> */}

        <Route exact path={mainRoutes.login}>
            <LoginPage />
        </Route>
    </Switch>
);

export default Home;
