import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CreateIcon from '@material-ui/icons/Create';
import { Toggle } from '../Toggle/Toggle';

export const Permission = (props) => {
    const [checked, setChecked] = useState(false);
    const { permissionGroup, userPermissions, setUserPermissions, children, isSingle, enabled } = props;

    useEffect(() => {
        let hasAny = false;
        permissionGroup.Permissions.every((p) => {
            if (userPermissions.findIndex((up) => up.permissionId === p.id) >= 0) {
                hasAny = true;
            }
            return !hasAny;
        });
        setChecked(hasAny);
    }, [permissionGroup, userPermissions]);

    return (
        <div className="border-layout-background no-user-select-recursive hover:border-layout-background-dark overflow-hidden rounded-md border-2">
            {/* eslint-disable-next-line */}
            <div className="bg-layout-background hover:bg-layout-background-dark pointer-events-none flex items-center px-1.5 py-3">
                <Toggle checked={checked} />
                {/* <p className="text-lg font-bold ml-1.5 whitespace-nowrap">{permissionGroup.name}</p> */}
                <p className="ml-1.5 whitespace-nowrap text-lg font-bold">{permissionGroup.name}</p>
            </div>

            <div className={`${(permissionGroup.Permissions.length > 0 || isSingle) && 'p-1.5'}`}>
                {isSingle ? (
                    <div className="text-white">{children}</div>
                ) : (
                    <>
                        {permissionGroup.Permissions.map((permission) => (
                            <Row
                                key={permission.id}
                                enabled={enabled}
                                permission={permission}
                                userPermissions={userPermissions}
                                setUserPermissions={setUserPermissions}
                            />
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};

const Row = (props) => {
    const { permission, userPermissions, setUserPermissions, enabled } = props;
    const [activePermission, setActivePermission] = useState(0);

    const handlePermissionChange = (type) => {
        if (type === 'NONE') {
            setUserPermissions(userPermissions.filter((p) => p.permissionId !== permission.id));
            return;
        }

        const pIndex = userPermissions.findIndex((p) => p.permissionId === permission.id);

        if (pIndex >= 0) {
            const newUserPermissions = [...userPermissions];
            const newPermission = { ...userPermissions[pIndex] };
            newPermission.permissionType = type;
            newUserPermissions[pIndex] = newPermission;
            setUserPermissions(newUserPermissions);
        } else {
            setUserPermissions([
                ...userPermissions,
                {
                    permissionId: permission.id,
                    permissionType: type,
                },
            ]);
        }
    };

    useEffect(() => {
        const find = userPermissions.filter((p) => p.permissionId === permission.id);
        if (find.length > 0) {
            // eslint-disable-next-line
            find[0].permissionType === 'VIEW' ? setActivePermission(1) : setActivePermission(2);
        } else setActivePermission(0);
    }, [userPermissions, permission]);

    return (
        <div className="mb-1.5 flex items-center last:mb-0">
            <div className="mr-4 flex cursor-pointer items-center justify-start text-white">
                {/* eslint-disable-next-line */}
                <div
                    onClick={() => (enabled ? handlePermissionChange('NONE') : null)}
                    className={`rounded-bl-sm rounded-tl-sm p-1 duration-200 ${
                        activePermission === 0 ? 'bg-primary-main' : 'bg-layout-background'
                    }`}
                >
                    <VisibilityOffOutlinedIcon />
                </div>

                {/* eslint-disable-next-line */}
                <div
                    onClick={() => (enabled ? handlePermissionChange('VIEW') : null)}
                    className={`p-1 duration-200 ${
                        activePermission === 1 ? 'bg-primary-main' : 'bg-layout-background'
                    }`}
                >
                    <VisibilityOutlinedIcon />
                </div>

                {/* eslint-disable-next-line */}
                <div
                    onClick={() => (enabled ? handlePermissionChange('ALL') : null)}
                    className={`rounded-br-sm rounded-tr-sm p-1 duration-200 ${
                        activePermission === 2 ? 'bg-primary-main' : 'bg-layout-background'
                    }`}
                >
                    <CreateIcon />
                </div>
            </div>
            <p className="pr-8 font-semibold">{permission.name}</p>
        </div>
    );
};

Row.propTypes = {
    // eslint-disable-next-line
    permission: PropTypes.object,
    // eslint-disable-next-line
    userPermissions: PropTypes.array,
    setUserPermissions: PropTypes.func,
    enabled: PropTypes.bool,
};

Row.defaultProps = {
    permission: null,
    userPermissions: [],
    setUserPermissions: () => null,
    enabled: true,
};

Permission.propTypes = {
    // eslint-disable-next-line
    permissionGroup: PropTypes.object,
    // eslint-disable-next-line
    userPermissions: PropTypes.array,
    setUserPermissions: PropTypes.func,
    isSingle: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    enabled: PropTypes.bool,
};

Permission.defaultProps = {
    permissionGroup: null,
    userPermissions: [],
    setUserPermissions: () => null,
    isSingle: false,
    children: <></>,
    enabled: true,
};
