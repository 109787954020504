import React, { memo } from 'react';
import PropTypes from 'prop-types';
import DateUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const AppWrapper = ({ children }) => {
    const theme = createTheme({
        palette: {
            // type: 'dark',
            primary: {
                light: '#328CF8',
                main: '#2C7EDC',
            },
            secondary: {
                main: '#9c7c35',
                light: '#c7a353',
            },

            text: {
                primary: '#ffffff',
                secondary: '#9c7c35',
            },
        },
        typography: {
            fontFamily: [
                'Sarabun',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(', '),
        },
        props: {
            MuiInputLabel: {
                shrink: true,
            },
            MuiInput: {
                disableUnderline: true,
                autoComplete: 'off',
            },
            MuiButton: {
                disableElevation: true,
                variant: 'contained',
            },
        },
        overrides: {
            MuiButton: {
                root: {
                    textTransform: 'none',
                    fontSize: '1rem',
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    padding: '.5rem 1.25rem',
                    borderRadius: '.5rem',
                },
                text: {
                    textTransform: 'none',
                    fontSize: '1rem',
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    padding: '.5rem 1.25rem',
                },
                contained: {
                    backgroundColor: 'rgba(29, 140, 248, 0.16)',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: 'rgba(29, 140, 248, 0.32)',
                    },

                    '&$disabled': {
                        color: 'rgb(215 215 215)',
                        backgroundColor: 'rgb(175 175 175)',
                    },
                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: '#328CF8',
                    },
                },
                containedSecondary: {
                    '&:hover': {
                        backgroundColor: '#9c7c35',
                    },
                },
            },

            MuiLink: {
                root: {
                    transition: 'color .2s ease',
                    '&.MuiTypography-colorPrimary': {
                        '&:hover': {
                            color: '#328CF8',
                        },
                    },
                    '&.MuiTypography-colorSecondary': {
                        '&:hover': {
                            color: '#9c7c35',
                        },
                    },
                },
                underlineHover: {
                    textDecoration: 'underline',
                },
            },

            MuiTextField: {
                root: {
                    width: '100%',
                    position: 'relative',
                    display: 'inline-flex',
                    flexDirection: 'column',
                },
            },

            MuiInputLabel: {
                root: {
                    fontSize: '10px',
                    height: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    color: '#AFAFAF',
                    paddingLeft: 'calc(1rem + 1px)',
                    marginBottom: '5px',
                    whiteSpace: 'nowrap',

                    '&$disabled': {
                        color: '#AFAFAF',
                    },

                    '&$error': {
                        color: '#ff6969',
                    },
                },
                formControl: {
                    position: 'relative',
                },
                shrink: {
                    transform: 'translate(0, 0) scale(1)',
                },
            },

            MuiInputBase: {
                root: {
                    width: '100%',
                    border: '1px solid rgba(255, 255, 255, 0.05)',
                    borderRadius: '5px',
                    padding: 0,
                    transition: 'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                    '&$focused, &:hover': {
                        borderColor: '#2C7EDC',
                    },
                },
                input: {
                    fontSize: '1rem',
                    lineHeight: 1.2,
                    padding: '0 1rem',
                    '&::placeholder': {
                        fontWeight: 300,
                        fontStyle: 'italic',
                    },
                    '&$disabled': {
                        color: '#fff',
                    },
                },
                inputMultiline: {
                    padding: '0 1rem',
                    minHeight: 'calc(5rem - 2px)',
                },
                formControl: {
                    margin: '0 !important',
                },
            },

            MuiInput: {
                input: {
                    height: 'calc(2.75rem - 2px)',
                },
            },

            MuiFormHelperText: {
                root: {
                    position: 'relative',
                    marginTop: 0,
                    paddingLeft: '1rem',

                    '&$error': {
                        color: '#ff6969',
                    },
                },
            },

            MuiTableCell: {
                root: {
                    padding: '.65rem 1rem',
                    fontSize: '1rem',

                    '&:first-of-type': {
                        borderTopLeftRadius: '5px',
                        borderBottomLeftRadius: '5px',
                    },
                    '&:last-of-type': {
                        borderTopRightRadius: '5px',
                        borderBottomRightRadius: '5px',
                    },
                },

                head: {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    border: 'none',
                    padding: '1.25rem 1rem',
                    fontWeight: '700',
                },

                body: {
                    border: 'none',
                },
            },

            MuiTableRow: {
                root: {
                    borderTop: '1px solid rgba(255, 255, 255, 0.05)',
                    cursor: 'pointer',

                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        transition: 'background-color .2s ease',
                    },

                    '&:nth-of-type(1), &:nth-of-type(2)': {
                        border: 'none',
                    },
                },

                head: {
                    border: 'none',
                },
            },

            MuiPickersDay: {
                day: {
                    color: 'rgba(0, 0, 0, 0.25)',
                },
            },

            MuiPickersCalendarHeader: {
                switchHeader: {
                    '& .MuiTypography-root': {
                        color: 'rgba(0, 0, 0, 0.35) !important',
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateUtils}>{children}</MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

AppWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
};

const MemoizedAppWrapper = memo(AppWrapper);

export { MemoizedAppWrapper };
