import React, { useState, useEffect, useContext } from 'react';
import { Header, Filter, Search, TableSeparator, ExpandableRow, Tabs } from 'RaisisComponents';
import {
    Button,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Link,
    makeStyles,
    Chip,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ClientDetailsForm from 'components/shared/ClientDetailsForm';
import { clientRoutes } from 'routes';
import AddIcon from '@material-ui/icons/Add';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import PersonIcon from '@material-ui/icons/Person';
import { errorHandling, fingerPrintIconStyle } from 'utils';
import PropTypes from 'prop-types';
import API from 'utils/axios';
import { useQuery, useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet';
import LinearProgress from '@material-ui/core/LinearProgress';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ClientMembershipDetails from './ClientMembershipDetails';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useSnackbar } from 'notistack';
import SubscriptionTypeContext from 'contexts/SubscriptionTypeContext';

const useStyles = makeStyles(() => {
    return {
        error: {
            backgroundColor: `var(--error)`,
            '&:hover': {
                backgroundColor: `var(--error-light)`,
            },
        },
        active: {
            backgroundColor: `var(--primary-main)`,
        },
        blocked: {
            backgroundColor: `var(--secondary-main)`,
        },
        deleted: {
            backgroundColor: `var(--error)`,
        },
    };
});

const ViewClientsPage = () => {
    const history = useHistory();
    // const [membershipType, setMembershipType] = useState(null);
    // const [statusType, setStatusType] = useState(null);
    // const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [maximumInvalidatePage, setMaximumInvalidatePage] = useState(0);
    const [pagesArray, setPagesArray] = useState([]);

    const invalidationPage = Math.min(currentPage, maximumInvalidatePage);

    const { data: clients, status: clientsLoading } = useQuery(['clients', currentPage], () => {
        return API.get('customer', {
            params: {
                currentPage,
                perPage: 10,
            },
        });
    });

    useEffect(() => {
        if (clientsLoading !== 'success') return;
        const clientsLen = clients.data.data.count;
        let newPagesArray = new Array(Math.ceil(clientsLen / 10)).fill(0);
        newPagesArray = newPagesArray.map((_, index) => index + 1);
        setPagesArray(newPagesArray);
        setMaximumInvalidatePage(Math.ceil((clientsLen - 1) / 10));
    }, [clientsLoading, clients]);

    return (
        <>
            <Helmet>
                <title>Gestionare Clienți</title>
            </Helmet>

            <div className="relative">
                <Header
                    pageTitle="Gestionare clienți"
                    action={
                        <Button
                            color="primary"
                            startIcon={<AddIcon />}
                            style={{ borderRadius: '999px' }}
                            onClick={() => history.push(clientRoutes.create)}
                        >
                            Adaugă client nou
                        </Button>
                    }
                    // toolbar={
                    //     <div className="flex items-center">
                    //         <Filter
                    //             name="Tip abonament"
                    //             options={['Activi', 'Inactivi']}
                    //             selectedOption={membershipType}
                    //             setSelectedOption={setMembershipType}
                    //         />

                    //         <div className="w-8" />

                    //         <Filter
                    //             name="Status"
                    //             options={['Activi', 'Inactivi']}
                    //             selectedOption={statusType}
                    //             setSelectedOption={setStatusType}
                    //         />

                    //         <div className="w-8" />

                    //         <div className="w-full text-right">
                    //             <Search value={search} setValue={setSearch} />
                    //         </div>
                    //     </div>
                    // }
                />

                <div className="page-container">
                    {clientsLoading === 'loading' ? <LinearProgress /> : null}

                    {clientsLoading === 'success' ? (
                        <>
                            <TableContainer component={Box}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Client</TableCell>
                                            <TableCell>Abonament</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Telefon</TableCell>
                                            <TableCell>Mail</TableCell>
                                            <TableCell>Observații</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        <TableSeparator />
                                        {clients.data?.data?.customers?.map((client) => (
                                            <ClientRow
                                                client={client}
                                                key={client.id}
                                                invalidationPage={invalidationPage}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <div className="mt-8">
                                <Tabs activeTab={currentPage} setActiveTab={setCurrentPage} tabs={pagesArray} />
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </>
    );
};

const ClientRow = (props) => {
    const subscriptionTypes = useContext(SubscriptionTypeContext);
    const styles = useStyles();
    const { client, invalidationPage } = props;
    const [activeTab, setActiveTab] = useState(0);
    const [isUpdating, setIsUpdating] = useState(false);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    // const ClientActivity = () => {
    //     return (
    //         <TableContainer component={Box}>
    //             <Table>
    //                 <TableBody>
    //                     <TableSeparator />
    //                     {[1, 2, 3, 4, 5].map((index) => (
    //                         <TableRow key={index}>
    //                             <TableCell>12 APR 2021, 8:30</TableCell>
    //                             <TableCell>
    //                                 A fost emisa <Link href="/">Factura #7</Link>, in valoare de{' '}
    //                                 <span className="font-bold">560 lei</span>
    //                             </TableCell>
    //                         </TableRow>
    //                     ))}
    //                 </TableBody>
    //             </Table>
    //         </TableContainer>
    //     );
    // };

    // const ClientCashflow = () => {
    //     return (
    //         <>
    //             <TableContainer component={Box}>
    //                 <Table>
    //                     <TableHead>
    //                         <TableRow>
    //                             <TableCell>Ultima factură</TableCell>
    //                             <TableCell>Dată achitare</TableCell>
    //                             <TableCell>Sumă</TableCell>
    //                         </TableRow>
    //                     </TableHead>
    //                     <TableBody>
    //                         <TableSeparator />
    //                         {[1, 2, 3, 4, 5].map((index) => (
    //                             <TableRow key={index}>
    //                                 <TableCell>18 AUG 2021</TableCell>
    //                                 <TableCell>19 AUG 2021</TableCell>
    //                                 <TableCell>
    //                                     560 lei - <Link href="/">Factura #7</Link>
    //                                 </TableCell>
    //                             </TableRow>
    //                         ))}
    //                     </TableBody>
    //                 </Table>
    //             </TableContainer>
    //         </>
    //     );
    // };

    const handleDeleteClient = async () => {
        try {
            setIsUpdating(true);

            await API.delete('/customer_delete', {
                params: {
                    id: client.id,
                },
            });

            enqueueSnackbar('Ștergerea clientului a fost realizată cu success', { variant: 'success' });

            queryClient.invalidateQueries({ queryKey: ['clients', invalidationPage] });
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsUpdating(false);
        }
    };

    const handleBlockClient = async () => {
        try {
            setIsUpdating(true);

            await API.delete('/customer_block', {
                params: {
                    id: client.id,
                    isBlocked: !client.isBlocked,
                },
            });

            enqueueSnackbar(
                client.isBlocked
                    ? 'Deblocarea clientului a fost realizată cu success'
                    : 'Blocarea clientului a fost realizată cu success',
                { variant: 'success' },
            );

            queryClient.invalidateQueries({ queryKey: ['clients', invalidationPage] });
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <ExpandableRow
            colSpan={6}
            row={
                <>
                    <TableCell>
                        <div className="flex items-center">
                            <FingerprintIcon className="mr-4" style={fingerPrintIconStyle} />
                            {client.name}
                        </div>
                    </TableCell>
                    <TableCell>
                        {(() => {
                            const subscriptionName = subscriptionTypes.find(
                                (sT) => sT.id === client.subscription?.subscriptionTypeId,
                            )?.name;

                            if (!subscriptionName) return '';

                            return subscriptionName.charAt(0).toUpperCase() + subscriptionName.slice(1);
                        })()}
                    </TableCell>
                    <TableCell>
                        <Chip
                            size="small"
                            color={client.isBlocked ? 'secondary' : 'primary'}
                            className={client.isDeleted ? styles.error : ''}
                            label={<p>{client.isDeleted ? 'Șters' : client.isBlocked ? 'Blocat' : 'Activ'}</p>}
                        />
                    </TableCell>
                    <TableCell>{client.phoneNo}</TableCell>
                    <TableCell>{client.email}</TableCell>
                    <TableCell />
                </>
            }
            collapse={
                <div className="w-full">
                    <div className="mb-4 flex items-center gap-4">
                        <a
                            href={`https://${client.subdomain}.${process.env.REACT_APP_DOMAIN_URL}/`}
                            target="_blank"
                            rel="noreferrer"
                            className={`${isUpdating || client.isDeleted ? 'pointer-events-none' : ''}`}
                        >
                            <Button
                                startIcon={<VisibilityOutlinedIcon />}
                                color="primary"
                                disabled={isUpdating || client.isDeleted}
                            >
                                Accesează contul
                            </Button>
                        </a>

                        <Button
                            startIcon={<PersonIcon />}
                            onClick={() => history.push(clientRoutes.updateRoot + client.id)}
                            disabled={isUpdating || client.isDeleted}
                        >
                            Vezi Profilul
                        </Button>

                        {client.completedStatus === 'COMPLETED' && (
                            <Button
                                color="secondary"
                                startIcon={client.isBlocked ? <CheckCircleOutlineIcon /> : <BlockIcon />}
                                disabled={isUpdating || client.isDeleted}
                                onClick={handleBlockClient}
                            >
                                {client.isBlocked ? 'Deblochează utilizator' : 'Blochează utilizator'}
                            </Button>
                        )}

                        <Button
                            className={styles.error}
                            startIcon={<DeleteIcon />}
                            disabled={isUpdating || client.isDeleted}
                            onClick={handleDeleteClient}
                        >
                            Șterge utilizator
                        </Button>

                        {client.completedStatus !== 'COMPLETED' && (
                            <>
                                <Button
                                    color="secondary"
                                    startIcon={<AssignmentTurnedInIcon />}
                                    onClick={() =>
                                        history.push(
                                            clientRoutes.create +
                                                '?id=' +
                                                client.id +
                                                '&tab=' +
                                                `${
                                                    client.completedStatus === 'ERP_MODULES'
                                                        ? 1
                                                        : client.completedStatus === 'ERP_CONFIGURATION'
                                                        ? 2
                                                        : 3
                                                }`,
                                        )
                                    }
                                    disabled={isUpdating || client.isDeleted}
                                >
                                    Termină configurarea contului
                                </Button>
                            </>
                        )}
                    </div>

                    <div className="mb-4">
                        <Tabs
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            // tabs={['Activitate', 'Cashflow', 'Detalii abonament', 'Detalii client']}
                            tabs={['Detalii abonament', 'Detalii client']}
                        />
                    </div>

                    <div className="relative w-full">
                        {/* {activeTab === 0 && <ClientActivity />}
                        {activeTab === 1 && <ClientCashflow />} */}
                        {activeTab === 0 && <ClientMembershipDetails client={client} />}
                        {activeTab === 1 && (
                            <div className="p-4">
                                <ClientDetailsForm editable={false} client={client} canInteractWithAllFields={true} />
                            </div>
                        )}
                    </div>
                </div>
            }
        />
    );
};

ClientRow.propTypes = {
    // eslint-disable-next-line
    client: PropTypes.object,
    invalidationPage: PropTypes.number,
};

export default ViewClientsPage;
