export const baseRoute = '/'
export const clientRoute = `${baseRoute}client/`
export const userRoute = `${baseRoute}user/`
export const cashflowRoute = `${baseRoute}cashflow`

export const mainRoutes = {
  home: `${baseRoute}`,
  login: `${baseRoute}login`,
}

export const clientRoutes = {
  create: `${clientRoute}adaugare`,
  read: `${clientRoute}gestionare`,
  update: `${clientRoute}editare/:id`,
  updateRoot: `${clientRoute}editare/`
}

export const userRoutes = {
  create: `${userRoute}adaugare`,
  read: `${userRoute}gestionare`,
  update: `${userRoute}editare/:id`,
  updateRoot: `${userRoute}editare/`,
}

export const cashflowRoutes = {
  read: `${cashflowRoute}`
}