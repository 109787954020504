import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const FileUploadContainer = (props) => {
  const { children, onUpload } = props;
  const inputRef = useRef(null);
  const [inputName, setInputName] = useState(null);

  useEffect(() => {
    const name = Math.random();
    setInputName(name);
  }, []);

  return (
    <div>
      {inputName != null && (
        <>
          <span onClick={() => (inputRef?.current ? inputRef.current.click() : null)}>{children}</span>

          <input
            id={`${inputName}-file-input`}
            name={`${inputName}-file-input`}
            type="file"
            onChange={onUpload}
            ref={inputRef}
            multiple
            style={{
              display: 'none',
              visibility: 'hidden',
              opacity: 0,
              pointerEvents: 'none',
            }}
          />
        </>
      )}
    </div>
  );
};

FileUploadContainer.propTypes = {
  children: PropTypes.element,
  onUpload: PropTypes.func,
};

FileUploadContainer.defaultProps = {
  children: <></>,
  onUpload: () => null,
};

export default FileUploadContainer;
