import { useLayoutEffect, useState } from 'react';

const useScreenSizes = () => {
    const [sizes, setSizes] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSizes() {
            setSizes([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSizes);
        updateSizes();

        return () => window.removeEventListener('resize', updateSizes);
    }, []);

    return sizes;
};

export default useScreenSizes;
