import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { clientRoutes } from 'routes';
import { Login } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';
import UserContext from 'contexts/UserContext';
import API from 'utils/axios';
import { EMAIL_REGEX, PASSWORD_REGEX, whoAmI } from 'utils';

const LoginPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { user, setUser } = useContext(UserContext);
    const history = useHistory();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const [loading, setLoading] = useState(false);

    // Redirect user if already logged in
    useEffect(() => {
        if (user) history.push(clientRoutes.read);
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username.length) {
            enqueueSnackbar('Emailul nu este valid!', { variant: 'error' });
            return;
        }

        if (!username.match(EMAIL_REGEX)) {
            enqueueSnackbar('Emailul nu este formatat corect!', { variant: 'error' });
            return;
        }

        if (!password.length || !password.match(PASSWORD_REGEX)) {
            enqueueSnackbar('Parola nu este validă!', { variant: 'error' });
            return;
        }

        // Send to Backend
        setLoading(true);

        try {
            const res = await API.post('login', {
                email: username,
                password,
            });

            setLoading(false);

            const { token } = res.data;
            localStorage.setItem('accessToken', token);
            API.defaults.headers.common.Authorization = token;

            const findUser = await whoAmI();
            setUser(findUser);

            enqueueSnackbar('Bine ai venit înapoi!', { variant: 'success' });
            history.push(clientRoutes.read);
        } catch (err) {
            setLoading(false);
            // enqueueSnackbar(err.response.data.message, { variant: 'error' })
        }

        setLoading(false);
    };

    return (
        <>
            <Helmet>
                <title>Logare | M2M Superadmin</title>
            </Helmet>

            <Login
                platform="Superadmin"
                loading={loading}
                username={username}
                setUsername={setUsername}
                password={password}
                setPassword={setPassword}
                rememberMe={rememberMe}
                setRememberMe={() => setRememberMe(!rememberMe)}
                onSubmit={handleSubmit}
            />
        </>
    );
};

export default LoginPage;
