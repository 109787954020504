import React, { useState } from 'react';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DateRangeIcon from '@material-ui/icons/DateRange';
import dayjs from 'dayjs';

export const DatePicker = (props) => {
    const { date, setDate, variant, disabled } = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleDateChange = (newDate) => {
        setDate(newDate);
        setIsOpen(false);
    };

    return (
        <div className={`relative ${disabled ? 'pointer-events-none' : ''}`}>
            <div className="dropdown-z-index relative inline-block w-full flex-shrink-0 cursor-pointer">
                {/* eslint-disable-next-line */}
                <div
                    className={`dropdown-selected ${disabled ? 'disabled' : variant}`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className="flex items-center">
                        <DateRangeIcon />
                        <p className="ml-2">{date ? dayjs(date).format('DD MMM YYYY') : 'Alege o dată'}</p>
                    </div>
                    <KeyboardArrowDownIcon
                        className={`flex-shrink-0 transform transition-transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                    />
                </div>
            </div>

            <div className="hidden-default-date-picker">
                <MuiDatePicker
                    margin="normal"
                    variant="inline"
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    format="d MMM yyyy"
                    value={date}
                    onChange={handleDateChange}
                />
            </div>
        </div>
    );
};
DatePicker.propTypes = {
    date: PropTypes.string,
    setDate: PropTypes.func,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
};
DatePicker.defaultProps = {
    date: null,
    setDate: () => null,
    variant: 'default',
    disabled: false,
};
