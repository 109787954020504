import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { mainRoutes, clientRoutes, userRoutes, cashflowRoutes } from 'routes';

import Home from './Home/Home';
import Client from './Client/Client';
// import User from './User/User';
import NotFound from '../components/shared/NotFound';
// import Cashflow from './Cashflow/Cashflow';

const Root = () => (
    <Switch>
        {/* [mainRoutes.home, mainRoutes.login] */}
        <Route exact path="/">
            <Redirect to={clientRoutes.read} />
        </Route>

        <Route exact path={mainRoutes.login}>
            <Home />
        </Route>

        <Route exact path={[clientRoutes.create, clientRoutes.read, clientRoutes.update]}>
            <Client />
        </Route>

        {/* <Route exact path={[userRoutes.create, userRoutes.read, userRoutes.update]}>
            <User />
        </Route> */}

        {/* <Route exact path={[cashflowRoutes.read]}>
            <Cashflow />
        </Route> */}

        <Route path="*">
            <NotFound />
        </Route>
    </Switch>
);

export default Root;
