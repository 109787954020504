import React from 'react';
import PropTypes from 'prop-types';

export const Header = (props) => {
    const { pageTitle, action, toolbar, toolbarSecondary, toolbarTertiary } = props;
    return (
        <>
            <div className="relative w-full bg-black-light py-10 px-16 mb-1 flex items-center md:py-6 md:px-6 flex-wrap gap-4">
                <h1 className="mr-5 md:text-3xl sm:text-2xl">{pageTitle}</h1>
                {action}
            </div>

            {toolbar && (
                <div className="relative w-full bg-black-light py-4 px-16  md:px-8" style={{ zIndex: 3 }}>
                    {toolbar}
                </div>
            )}

            {toolbarSecondary && (
                <div className="relative w-full bg-black-light py-4 px-16 mt-1  md:px-6" style={{ zIndex: 2 }}>
                    {toolbarSecondary}
                </div>
            )}

            {toolbarTertiary && (
                <div className="relative w-full bg-black-light py-4 px-16 mt-1 md:px-6" style={{ zIndex: 1 }}>
                    {toolbarTertiary}
                </div>
            )}
        </>
    );
};

Header.propTypes = {
    pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    action: PropTypes.element,
    toolbar: PropTypes.element,
    toolbarSecondary: PropTypes.element,
    toolbarTertiary: PropTypes.element,
};

Header.defaultProps = {
    pageTitle: '',
    action: null,
    toolbar: null,
    toolbarSecondary: null,
    toolbarTertiary: null,
};
