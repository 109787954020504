import React, { useState } from 'react';
import PropTypes from 'prop-types';

const BasicTooltip = (props) => {
    const { children, tip, position } = props;
    const [hover, setHover] = useState(false);

    const positionMapper = {
        top: 'translate(-50%, -110%)',
        right: 'translate(15%, -10%)',
        left: 'translate(-115%, -10%)',
        bottom: 'translate(-50%, 110%)',
        topRight: 'translate(-20%, -110%)',
    };

    return (
        <div className="relative" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <div
                className={`pointer-events-none absolute left-1/2 top-0 z-50 rounded-md p-2 transition-all duration-200 sm:hidden ${
                    hover ? 'opacity-100' : 'opacity-0'
                }`}
                style={{
                    backgroundColor: `rgb(32 32 32 / 90%)`,
                    transform: hover ? positionMapper[position] : 'translate(-50%, 0)',
                }}
            >
                <p className="whitespace-nowrap">{tip}</p>
            </div>

            {children}
        </div>
    );
};

BasicTooltip.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    tip: PropTypes.string,
    position: PropTypes.string,
};

BasicTooltip.defaultProps = {
    children: <></>,
    tip: '',
    position: 'top',
};

export default BasicTooltip;
