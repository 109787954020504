import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Search } from '../Search/Search';

export const Dropdown = (props) => {
    const { options, selectedOption, setSelectedOption, placeholder, variant, icon, onlyIcon, disabled } = props;
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const isContent = 'props' in options;

    const exceptions = ['--exc-01'];

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div
                className={`relative w-full inline-block flex-shrink-0 ${
                    disabled ? 'cursor-not-allowed	' : 'cursor-pointer '
                }`}
            >
                {/* Selected option */}
                {/* eslint-disable-next-line */}
                <div
                    className={`dropdown-selected ${variant} ${open ? 'clicked' : ''} ${
                        selectedOption !== null ? 'hasValue' : ''
                    } ${icon !== null && 'is-icon'}`}
                    onClick={!disabled ? () => setOpen(!open) : undefined}
                >
                    {icon !== null && !onlyIcon ? (
                        <>
                            <div>{icon}</div>
                            <p className="pointer-events-none whitespace-nowrap font-medium">
                                {selectedOption !== null ? options[selectedOption] : placeholder}
                            </p>
                        </>
                    ) : icon !== null && onlyIcon ? (
                        <>{icon}</>
                    ) : (
                        <>
                            <p className="pointer-events-none whitespace-nowrap font-medium">
                                {selectedOption !== null ? options[selectedOption] : placeholder}
                            </p>
                            <KeyboardArrowDownIcon
                                className={`flex-shrink-0 transform ${open ? 'rotate-180' : 'rotate-0'}`}
                                style={{ transition: 'transform .2s ease' }}
                            />
                        </>
                    )}
                </div>

                {/* Options */}
                {open && options && (
                    <div className={`dropdown-options ${variant}`}>
                        {isContent ? (
                            <>{options}</>
                        ) : (
                            <>
                                <div className="w-full search-li sticky top-0 ">
                                    <Search light value={searchValue} setValue={setSearchValue} />
                                </div>
                                <ul className=" z-50 ">
                                    {options.map((option, index) => {
                                        if (
                                            exceptions.indexOf(String(option).toLowerCase()) < 0 &&
                                            String(option).toLowerCase().search(searchValue.toLocaleLowerCase()) >= 0
                                        )
                                            return (
                                                // eslint-disable-next-line
                                                <li
                                                    key={Math.random()}
                                                    onClick={() => {
                                                        setSelectedOption(index);
                                                        setOpen(false);
                                                    }}
                                                    className={`${index === selectedOption && 'active'}`}
                                                >
                                                    <p className="text-sm font-medium">
                                                        {Boolean(option) && option.length > 32
                                                            ? `${option.slice(0, 64).trim()}...`
                                                            : option}
                                                    </p>
                                                </li>
                                            );
                                        return null;
                                    })}
                                </ul>
                            </>
                        )}
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};

Dropdown.propTypes = {
    // eslint-disable-next-line
    options: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    selectedOption: PropTypes.number,
    setSelectedOption: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.string,
    icon: PropTypes.element,
    disabled: PropTypes.bool,
    onlyIcon: PropTypes.bool,
};

Dropdown.defaultProps = {
    options: null,
    selectedOption: null,
    setSelectedOption: () => null,
    placeholder: 'Alege opțiune',
    variant: 'default',
    icon: null,
    disabled: false,
    onlyIcon: false,
};
